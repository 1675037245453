const config = {
  rootProjectId: "root",
  uiBucketName: "pretamanger.c.retter.io",
  appUrl: "https://api.pretamanger.retter.io/",
  cosUrl: "api.pretamanger.retter.io",
  version: "2.1.1",
  captchaKey: "6LfLJG0pAAAAAFTW3g393X04g1nkyR0T8JLCBNyw",
  stage: "PROD"
}
export default config

