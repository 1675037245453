import React, {Component} from "react";
import {Button, Table, notification} from "antd";
import {RootProjectContext} from "../Contexts/RootProjectContext";
import _ from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import { RootProjectClassEnums } from "../Api/APIService";
import RBS from "@retter/sdk";

interface Props {
    projectId: string
    rootRbsSdk: RBS
}

interface State {
    loading: boolean
}

export class DependenciesLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  deleteDeleteDependency = async (dependencyName: string) => {
    try {
      const retterClassInstance = await this.props.rootRbsSdk.getCloudObject({
        classId: RootProjectClassEnums.Project,
        instanceId: this.props.projectId,
        useLocal: true,
      });

      const response = await retterClassInstance.call<any>({
        method: "deleteDependency",
        body: {
          dependencyName: dependencyName,
        },
      });

      if (!response || response.status >= 299) {
        notification.error({
          placement: "bottomRight",
          message: response ? response.data : "Something went wrong !",
        });
        return false;
      }

      notification["success"]({
        placement: "bottomRight",
        message: `${dependencyName} successfully deleted`,
        description: "Success",
      });
    } catch (e: any) {
      notification.error({
        placement: "bottomRight",
        message:
          e.response?.data?.message || e.message || "Something went wrong !",
      });
    }
  };

  render() {
    return (
      <>
        <RootProjectContext.Consumer>
          {(ctx) =>
            ctx && ctx.detail ? (
              <>
                <Table
                  columns={[
                    {
                      title: <>Dependency Name</>,
                      key: "name",
                      render: (model: {
                        dependencyName: string;
                        hash: string;
                      }) => model.dependencyName,
                    },
                    {
                      title: <>Dependency Hash</>,
                      key: "hash",
                      render: (model: {
                        dependencyName: string;
                        hash: string;
                      }) => model.hash,
                    },
                    {
                      title: <>Delete</>,
                      key: "hash",
                      render: (model: any) => {
                        console.log(model);
                        return (
                          <>
                            <Button
                              onClick={() =>
                                this.deleteDeleteDependency(
                                  model.dependencyName
                                )
                              }
                              danger
                            >
                              <DeleteOutlined /> Delete
                            </Button>
                          </>
                        );
                      },
                    },
                  ]}
                  dataSource={_.sortBy(
                    Object.keys(ctx.detail.layers || {}).map((d) => {
                      return {
                        sortKey: d.toLowerCase(),
                        key: d,
                        dependencyName: d,
                        hash:
                          ctx.detail && ctx.detail.layers
                            ? ctx.detail.layers[d].hash
                            : "-",
                        version:
                          ctx.detail && ctx.detail.layers
                            ? ctx.detail.layers[d].version
                            : "-",
                      };
                    }),
                    "sortKey"
                  )}
                />
              </>
            ) : null
          }
        </RootProjectContext.Consumer>
      </>
    );
  }
}
