import React, { Component } from 'react'
import { RootProjectClassEnums, RootProjectClassMethods } from '../../Api/APIService'
import { Badge, Button, Col, Dropdown, Layout, Menu, message, Modal, notification, Result, Row, Space, Tabs, Tag, Timeline, Tooltip, Typography } from 'antd'
import Editor, { DiffEditor, Monaco } from '@monaco-editor/react'
import { RouteComponentProps } from 'react-router-dom'
import Icon, {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DeleteOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    FileOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    LinkOutlined,
    MoreOutlined,
    RadiusSettingOutlined,
    RollbackOutlined,
    SaveOutlined,
    SubnodeOutlined,
    ThunderboltOutlined,
} from '@ant-design/icons'
import CreateClassFileModal from '../../Modals/CreateClassFileModal'
import { gunzipSync, gzipSync } from 'zlib'
import { COSFileStatus, ISaveCloudObjectFileModel } from '../../Interfaces/CloudObjetsInterfaces'
import CustomSpinner from '../../Components/CustomSpinner'
import { getNomnoml, NomnomlObject } from '../CosLayouts/CosHelpers'
import CosInstanceList from '../CosLayouts/CosInstanceList'
import { ActionTypes, topMenuStore } from '../../Actions/Actions'
import YAML from 'yaml'
import CosClassDangerZone from '../CosLayouts/CosClassDangerZone'
import CosInstancesDangerZone from '../CosLayouts/CosInstancesDangerZone'
import { GlobalHelpers } from '../../GlobalHelpers'
import RBS, { RetterCloudObject } from '@retter/sdk'
import { IProjectContext, RootProjectContext } from '../../Contexts/RootProjectContext'
import { Unsubscribable } from 'rxjs'
import { setupTypeAcquisition } from '@typescript/ata'
// @ts-ignore
import { UnregisterCallback } from 'history'
import _ from 'lodash'
import { ArgsProps } from 'antd/es/notification/interface'
import { withAntdToken } from '../../Components/with-antd-token-hoc'
import { ThemeContext } from '../../Contexts/ThemeContext'

const { Text, Title } = Typography

const TypescriptSVG = () => (
    <svg viewBox="0 0 128 128" width="1em" height="1em">
        <path fill="#fff" d="M22.67 47h99.67v73.67H22.67z"></path>
        <path
            data-name="original"
            fill="#007acc"
            d="M1.5 63.91v62.5h125v-125H1.5zm100.73-5a15.56 15.56 0 017.82 4.5 20.58 20.58 0 013 4c0 .16-5.4 3.81-8.69 5.85-.12.08-.6-.44-1.13-1.23a7.09 7.09 0 00-5.87-3.53c-3.79-.26-6.23 1.73-6.21 5a4.58 4.58 0 00.54 2.34c.83 1.73 2.38 2.76 7.24 4.86 8.95 3.85 12.78 6.39 15.16 10 2.66 4 3.25 10.46 1.45 15.24-2 5.2-6.9 8.73-13.83 9.9a38.32 38.32 0 01-9.52-.1 23 23 0 01-12.72-6.63c-1.15-1.27-3.39-4.58-3.25-4.82a9.34 9.34 0 011.15-.73L82 101l3.59-2.08.75 1.11a16.78 16.78 0 004.74 4.54c4 2.1 9.46 1.81 12.16-.62a5.43 5.43 0 00.69-6.92c-1-1.39-3-2.56-8.59-5-6.45-2.78-9.23-4.5-11.77-7.24a16.48 16.48 0 01-3.43-6.25 25 25 0 01-.22-8c1.33-6.23 6-10.58 12.82-11.87a31.66 31.66 0 019.49.26zm-29.34 5.24v5.12H56.66v46.23H45.15V69.26H28.88v-5a49.19 49.19 0 01.12-5.17C29.08 59 39 59 51 59h21.83z"
        ></path>
    </svg>
)

const JavascriptSVG = () => (
    <svg viewBox="0 0 128 128" width="1em" height="1em">
        <path fill="#F0DB4F" d="M1.408 1.408h125.184v125.185H1.408z"></path>
        <path
            fill="#323330"
            d="M116.347 96.736c-.917-5.711-4.641-10.508-15.672-14.981-3.832-1.761-8.104-3.022-9.377-5.926-.452-1.69-.512-2.642-.226-3.665.821-3.32 4.784-4.355 7.925-3.403 2.023.678 3.938 2.237 5.093 4.724 5.402-3.498 5.391-3.475 9.163-5.879-1.381-2.141-2.118-3.129-3.022-4.045-3.249-3.629-7.676-5.498-14.756-5.355l-3.688.477c-3.534.893-6.902 2.748-8.877 5.235-5.926 6.724-4.236 18.492 2.975 23.335 7.104 5.332 17.54 6.545 18.873 11.531 1.297 6.104-4.486 8.08-10.234 7.378-4.236-.881-6.592-3.034-9.139-6.949-4.688 2.713-4.688 2.713-9.508 5.485 1.143 2.499 2.344 3.63 4.26 5.795 9.068 9.198 31.76 8.746 35.83-5.176.165-.478 1.261-3.666.38-8.581zM69.462 58.943H57.753l-.048 30.272c0 6.438.333 12.34-.714 14.149-1.713 3.558-6.152 3.117-8.175 2.427-2.059-1.012-3.106-2.451-4.319-4.485-.333-.584-.583-1.036-.667-1.071l-9.52 5.83c1.583 3.249 3.915 6.069 6.902 7.901 4.462 2.678 10.459 3.499 16.731 2.059 4.082-1.189 7.604-3.652 9.448-7.401 2.666-4.915 2.094-10.864 2.07-17.444.06-10.735.001-21.468.001-32.237z"
        ></path>
    </svg>
)

const RBSICONSVG = () => (
    <svg width="413" height="136" viewBox="0 0 413 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.3518 128.119C63.6729 128.119 67.1759 124.617 67.1759 120.295C67.1759 115.974 63.6729 112.471 59.3518 112.471C55.0306 112.471 51.5277 115.974 51.5277 120.295C51.5277 124.617 55.0306 128.119 59.3518 128.119ZM75 120.295C75 128.938 67.994 135.944 59.3518 135.944C50.7095 135.944 43.7035 128.938 43.7035 120.295C43.7035 111.653 50.7095 104.647 59.3518 104.647C67.9941 104.647 75 111.653 75 120.295Z"
            fill="#ccc"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.235 128.12C63.5561 128.12 67.0591 124.617 67.0591 120.296C67.0591 115.975 63.5561 112.472 59.235 112.472C54.9138 112.472 51.4109 115.975 51.4109 120.296C51.4109 124.617 54.9138 128.12 59.235 128.12Z"
            fill="#ccc"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4414 128.471C20.0708 128.471 23.8237 124.718 23.8237 120.088C23.8237 115.459 20.0708 111.706 15.4414 111.706C10.8119 111.706 7.05902 115.459 7.05902 120.088C7.05902 124.718 10.8119 128.471 15.4414 128.471Z"
            fill="#ccc"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.235 65.4719C63.5561 65.4719 67.0591 61.969 67.0591 57.6478C67.0591 53.3267 63.5561 49.8237 59.235 49.8237C54.9138 49.8237 51.4109 53.3267 51.4109 57.6478C51.4109 61.9689 54.9138 65.4719 59.235 65.4719Z"
            fill="#ccc"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.3518 65.4723C63.6729 65.4723 67.1759 61.9693 67.1759 57.6482C67.1759 53.327 63.6729 49.8241 59.3518 49.8241C55.0306 49.8241 51.5277 53.327 51.5277 57.6482C51.5277 61.9693 55.0306 65.4723 59.3518 65.4723ZM75 57.6482C75 66.2904 67.9941 73.2964 59.3518 73.2964C50.7095 73.2964 43.7035 66.2904 43.7035 57.6482C43.7035 49.0059 50.7095 41.9999 59.3518 41.9999C67.9941 41.9999 75 49.0059 75 57.6482Z"
            fill="#ccc"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.6482 128.119C19.9693 128.119 23.4723 124.617 23.4723 120.295C23.4723 115.974 19.9693 112.471 15.6482 112.471C11.327 112.471 7.82404 115.974 7.82404 120.295C7.82404 124.617 11.327 128.119 15.6482 128.119ZM31.2964 120.295C31.2964 128.938 24.2904 135.944 15.6482 135.944C7.00587 135.944 -7.74007e-05 128.938 -7.66451e-05 120.295C-7.58896e-05 111.653 7.00588 104.647 15.6482 104.647C24.2904 104.647 31.2964 111.653 31.2964 120.295Z"
            fill="#ccc"
        />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.5881 111.265V70.2354H63.5293V111.265H55.5881Z" fill="#ccc" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M55.6575 70.9736C55.658 70.9758 55.6584 70.9781 59.5588 70.2351C63.4593 69.4922 63.4598 69.4946 63.4602 69.4971L63.4613 69.5025L63.4635 69.5147L63.4689 69.5442C63.4729 69.5662 63.4776 69.5928 63.4829 69.6239C63.4934 69.6861 63.5064 69.7664 63.5207 69.8636C63.5494 70.058 63.5839 70.321 63.616 70.644C63.68 71.2883 63.7355 72.1807 63.7148 73.2486C63.6738 75.3677 63.3305 78.2821 62.0732 81.3405C59.4201 87.794 53.113 93.8898 40.1525 95.357C29.4548 96.5681 25.9064 100.613 24.5985 103.371C23.8758 104.894 23.6619 106.382 23.6366 107.507C23.624 108.065 23.6584 108.511 23.6915 108.797C23.7079 108.939 23.7236 109.038 23.7322 109.088C23.7365 109.113 23.739 109.125 23.7388 109.124L23.7363 109.112L23.7334 109.099L23.7315 109.09L23.7304 109.085C23.7298 109.082 23.7292 109.08 19.8532 109.941C15.9772 110.802 15.9766 110.8 15.9759 110.797L15.9746 110.791L15.9717 110.778L15.9651 110.747C15.9603 110.724 15.9549 110.697 15.9488 110.666C15.9366 110.605 15.9221 110.528 15.9063 110.436C15.8746 110.252 15.8373 110.007 15.8029 109.71C15.7343 109.117 15.6755 108.302 15.6974 107.329C15.741 105.394 16.106 102.745 17.4236 99.9673C20.1965 94.1216 26.5744 88.9022 39.2593 87.4662C49.6813 86.2864 53.3007 81.794 54.7285 78.321C55.5116 76.4161 55.7473 74.5328 55.7752 73.0948C55.7889 72.3843 55.7514 71.8084 55.7137 71.429C55.6949 71.2401 55.6764 71.1025 55.6648 71.0241C55.659 70.985 55.655 70.9609 55.6537 70.9529C55.653 70.9488 55.653 70.9488 55.6537 70.953L55.6554 70.9624L55.6568 70.9695L55.6575 70.9736Z"
            fill="#ccc"
        />
        <path
            d="M99 134.085V81.7916C99 67.1061 101.733 56.954 107.199 51.3352C109.868 48.5258 113.11 46.4188 116.923 45.0141C120.736 43.6094 124.041 42.7793 126.838 42.5239C129.761 42.2685 133.702 42.1408 138.659 42.1408H155.438V58.231H135.227C132.685 58.231 130.715 58.2948 129.316 58.4225C127.918 58.5502 126.266 58.9972 124.359 59.7634C122.452 60.5296 120.8 61.7427 119.402 63.4028C116.987 66.4676 115.779 72.5972 115.779 81.7916V134.085H99Z"
            fill="#ccc"
        />
        <path
            d="M158.242 58.231L192.372 23.9437V42.1408H218.112V58.231H192.372V97.6901C192.372 102.16 193.071 105.863 194.469 108.8C195.994 111.737 198.155 113.844 200.952 115.121C203.748 116.27 206.354 117.037 208.769 117.42C211.311 117.803 214.426 117.994 218.112 117.994V134.085C203.494 134.085 192.689 131.275 185.698 125.656C178.707 120.038 175.211 110.715 175.211 97.6901V58.231H158.242Z"
            fill="#ccc"
        />
        <path
            d="M236.297 0H253.457V88.1127C253.457 98.2009 256 106.054 261.084 111.673C266.296 117.164 273.414 119.91 282.439 119.91C291.846 119.91 299.218 117.228 304.557 111.865C309.896 106.374 312.565 98.4563 312.565 88.1127C312.565 78.2798 309.641 70.554 303.794 64.9352C298.074 59.1887 290.511 56.3155 281.105 56.3155C273.096 56.3155 266.169 58.6141 260.322 63.2113V45.9718C267.313 42.1409 274.876 40.2254 283.011 40.2254C296.485 40.2254 307.608 44.631 316.378 53.4423C325.276 62.1258 329.725 73.6826 329.725 88.1127C329.725 103.309 325.086 115.121 315.806 123.549C306.654 131.85 295.087 136 281.105 136C267.758 136 256.953 131.658 248.691 122.975C240.428 114.291 236.297 102.67 236.297 88.1127V0Z"
            fill="#ccc"
        />
        <path
            d="M409.187 42.1408V58.231H375.438C371.752 58.231 368.574 58.9333 365.905 60.338C363.235 61.7427 361.9 63.7221 361.9 66.2761C361.9 67.8085 362.218 69.2131 362.854 70.4901C363.489 71.6394 364.188 72.5972 364.951 73.3634C365.841 74.1296 367.112 74.9596 368.765 75.8535C370.544 76.6197 371.879 77.1944 372.769 77.5775C373.786 77.8329 377.853 79.1099 384.971 81.4084C392.09 83.707 397.365 85.6225 400.797 87.1549C404.229 88.6873 406.835 90.7944 408.615 93.4761C410.521 96.0301 411.729 98.584 412.237 101.138C412.746 103.564 413 105.991 413 108.417C413 125.529 401.306 134.085 377.917 134.085H344.931V117.994H377.917C389.865 117.994 395.84 114.802 395.84 108.417C395.84 105.352 394.06 102.798 390.501 100.755C387.069 98.7117 382.811 96.9878 377.726 95.5831C372.769 94.1784 367.748 92.5822 362.663 90.7944C357.706 88.8789 353.447 85.8779 349.888 81.7916C346.456 77.5775 344.74 72.4056 344.74 66.2761C344.74 58.4864 347.727 52.5484 353.702 48.462C359.676 44.2479 366.921 42.1408 375.438 42.1408H409.187Z"
            fill="#ccc"
        />
    </svg>
)

const TypescriptIcon = (props: any) => <Icon component={TypescriptSVG} {...props} />
const JavascriptIcon = (props: any) => <Icon component={JavascriptSVG} {...props} />
const RBSIcon = (props: any) => <Icon component={RBSICONSVG} {...props} />

interface FileDetail {
    name: string
    content: string
    _updateToken: string
}

interface ChangedFileDetail extends FileDetail {
    status: COSFileStatus
}

interface Props {
    token: any
    rootProjectSdk: RBS
    routeComponentProps: RouteComponentProps<{ projectId: string; classId: string }>
}

interface State {
    data: FileDetail[]
    tabPanes: FileDetail[]
    changedFiles: ChangedFileDetail[]
    activeTabPane: string
    changeHash: string
    loading: boolean
    saveChangesLoading: boolean
    deployLoading: boolean
    nomnomlObject?: NomnomlObject
    publicSub?: Unsubscribable
    utilClassInstanceString?: string
    routeHistoryUnregister?: UnregisterCallback
    moreButtonLoading: boolean
}

class CoEditorLayout extends Component<Props, State> {
    projectId: string
    classId: string
    classInstance?: RetterCloudObject
    projectInstance?: RetterCloudObject
    monaco?: Monaco
    // ata: (code: string) => void
    editorDebouncingTimer: boolean = false
    ctx?: IProjectContext

    constructor(props: Props) {
        super(props)
        this.projectId = this.props.routeComponentProps.match.params.projectId
        this.classId = this.props.routeComponentProps.match.params.classId
        this.state = {
            changeHash: '',
            changedFiles: [],
            activeTabPane: '',
            data: [],
            tabPanes: [],
            loading: true,
            saveChangesLoading: false,
            deployLoading: false,
            moreButtonLoading: false,
        }
        this.openFileInCodeEditor = this.openFileInCodeEditor.bind(this)
        this.generateRioFile = this.generateRioFile.bind(this)
        this.tabOnEdit = this.tabOnEdit.bind(this)
        this.tabsOnChange = this.tabsOnChange.bind(this)
        this.onEditorChange = this.onEditorChange.bind(this)
        this.createFileSuccessCallback = this.createFileSuccessCallback.bind(this)
        this.rollbackDelete = this.rollbackDelete.bind(this)
        this.init = this.init.bind(this)
        this.getFileStatusInChangedFiles = this.getFileStatusInChangedFiles.bind(this)
        this.showDiff = this.showDiff.bind(this)
        this.deployClass = this.deployClass.bind(this)
        this.handleEditorDidMount = this.handleEditorDidMount.bind(this)
        this.editorOnDefinitionClick = this.editorOnDefinitionClick.bind(this)
        this.onBack = this.onBack.bind(this)
        this.prepareMenu = this.prepareMenu.bind(this)
        // this.addLibraryToRuntime = this.addLibraryToRuntime.bind(this)
        this.beforeUnloadListener = this.beforeUnloadListener.bind(this)
        this.downloadHelperFile = this.downloadHelperFile.bind(this)
        // this.ata = setupTypeAcquisition({
        //     projectName: 'retter.io console',
        //     typescript: require('typescript') as typeof import('typescript'),
        //     logger: console,
        //     delegate: {
        //         receivedFile: this.addLibraryToRuntime,
        //         progress: (downloaded: number, total: number) => {},
        //         started: () => {
        //             //console.log("Downloading dependency")
        //         },
        //         finished: f => {
        //             //console.log("Downloaded dependency")
        //         },
        //     },
        // })
    }

    onBack() {
        this.props.routeComponentProps.history.push(`/project/${this.projectId}/Classes`)
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (
            prevState.changedFiles !== this.state.changedFiles ||
            prevState.saveChangesLoading !== this.state.saveChangesLoading ||
            prevState.deployLoading !== this.state.deployLoading ||
            prevState.activeTabPane !== this.state.activeTabPane ||
            prevState.moreButtonLoading !== this.state.moreButtonLoading
        ) {
            this.prepareMenu()
        }
    }

    prepareMenu() {
        const saveOrDeploy =
            this.state.changeHash !== this.getChangeHash(this.state.changedFiles) ? (
                <Button
                    icon={<SaveOutlined />}
                    disabled={this.state.changeHash === this.getChangeHash(this.state.changedFiles)}
                    type="default"
                    loading={this.state.saveChangesLoading}
                    onClick={async () => {
                        await this.saveChanges()
                    }}
                >
                    Save
                </Button>
            ) : (
                <Dropdown
                    overlay={
                        <>
                            <Menu mode={'inline'} selectedKeys={[]}>
                                <Menu.Item
                                    onClick={async () => {
                                        await this.deployClass()
                                    }}
                                >
                                    Project Deploy
                                </Menu.Item>
                                <Menu.Item
                                    onClick={async () => {
                                        await this.deployClass(true)
                                    }}
                                >
                                    Force Project Deploy
                                </Menu.Item>
                            </Menu>
                        </>
                    }
                >
                    <Button type={'primary'}>
                        <Space>
                            Project Deploy
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            )

        let deleteOrRollbackFile = undefined
        if (this.state.changedFiles.length && this.state.activeTabPane && this.state.activeTabPane !== 'template.yml') {
            const currentFile = this.state.changedFiles.find(f => f.name === this.state.activeTabPane)
            if (currentFile) {
                deleteOrRollbackFile =
                    currentFile.status === COSFileStatus.DELETED ? (
                        <Tooltip placement="left" title={this.state.activeTabPane}>
                            <Button
                                type={'link'}
                                icon={<RollbackOutlined />}
                                onClick={async () => {
                                    await this.rollbackDelete(this.state.activeTabPane)
                                }}
                            >
                                Rollback Delete
                            </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip placement="left" title={this.state.activeTabPane}>
                            <Button
                                type={'link'}
                                danger
                                icon={<DeleteOutlined />}
                                onClick={async () => {
                                    await this.deleteFile(this.state.activeTabPane)
                                }}
                            >
                                Delete File
                            </Button>
                        </Tooltip>
                    )
            }
        }
        topMenuStore.dispatch({
            type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
            data: {
                extraMenu: [
                    <Button
                        icon={<ThunderboltOutlined />}
                        type="default"
                        onClick={() => {
                            this.props.routeComponentProps.history.push(`/project/${this.projectId}/Classes/${this.classId}/instance/`)
                        }}
                    >
                        Test
                    </Button>,
                    <CosInstanceList projectId={this.ctx?.projectId!} classId={this.classId} rootProjectSdk={this.props.rootProjectSdk} classInstance={this.classInstance!} />,
                    saveOrDeploy,
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item>
                                    <CreateClassFileModal successCallback={this.createFileSuccessCallback} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Button
                                        onClick={async () => {
                                            await this.generateRioFile()
                                        }}
                                        type={'link'}
                                        icon={<SubnodeOutlined />}
                                    >
                                        Generate Rio File
                                    </Button>
                                </Menu.Item>
                                <Menu.SubMenu key="helper_files" title="Generate Helper File">
                                    <Menu.Item
                                        key="Typescript"
                                        onClick={async () => {
                                            await this.downloadHelperFile('typescript')
                                        }}
                                    >
                                        Typescript
                                    </Menu.Item>
                                    <Menu.Item
                                        key="Typescript Client"
                                        onClick={async () => {
                                            await this.downloadHelperFile('typescript-client')
                                        }}
                                    >
                                        Typescript Client
                                    </Menu.Item>
                                    <Menu.Item
                                        key="Swift Client"
                                        onClick={async () => {
                                            await this.downloadHelperFile('swift-client')
                                        }}
                                    >
                                        Swift Client
                                    </Menu.Item>
                                    <Menu.Item
                                        key="Kotlin Client"
                                        onClick={async () => {
                                            await this.downloadHelperFile('kotlin-client')
                                        }}
                                    >
                                        Kotlin Client
                                    </Menu.Item>
                                </Menu.SubMenu>
                                {deleteOrRollbackFile ? <Menu.Item>{deleteOrRollbackFile}</Menu.Item> : null}
                                <Menu.Divider />
                                <Menu.Item>
                                    <Button
                                        icon={<LinkOutlined />}
                                        type={'link'}
                                        onClick={() => {
                                            window.open(
                                                GlobalHelpers.prepareLogsLayoutLocation(this.projectId, {
                                                    from: Math.floor(Date.now() / 1000) - 60 * 5,
                                                    to: Math.floor(Date.now() / 1000) + 60,
                                                    filters: {
                                                        classId: {
                                                            operator: 'EQ',
                                                            value: this.classId,
                                                        },
                                                    },
                                                }),
                                                '_blank'
                                            )
                                        }}
                                    >
                                        Class Logs
                                    </Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <Button icon={<InfoCircleOutlined />} type="link" onClick={this.showEditorInfo}>
                                        Help
                                    </Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <CosClassDangerZone
                                        rootProjectSdk={this.props.rootProjectSdk}
                                        projectId={this.projectId}
                                        classId={this.classId}
                                        onSuccess={() => {
                                            this.props.routeComponentProps.history.push(`/project/${this.projectId}/Classes`)
                                        }}
                                    />


                                </Menu.Item>
                                <Menu.Item>
                                    <CosInstancesDangerZone
                                        rootProjectSdk={this.props.rootProjectSdk}
                                        projectId={this.projectId}
                                        classId={this.classId}
                                        onSuccess={() => {}}
                                    />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button loading={this.state.moreButtonLoading} icon={<MoreOutlined />} type={'default'} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            More
                            <DownOutlined />
                        </Button>
                    </Dropdown>,
                ],
            },
        })
    }

    beforeUnloadListener(ev: any) {
        if (ev) {
            if (this.state.changeHash !== this.getChangeHash(this.state.changedFiles)) {
                ev.preventDefault()
                return window.confirm('Are you sure you want to close?')
            }
            return false
        }
    }

    async generateHelperFile(language: 'typescript' | 'typescript-client' | 'swift-client' | 'kotlin-client') {
        const createInterfaceFileResponse = await this.ctx?.instance?.call<any>({
            method: RootProjectClassMethods.generateHelperFile,
            body: {
                language,
            },
        })
        if (createInterfaceFileResponse && createInterfaceFileResponse.data) {
            return createInterfaceFileResponse.data
        } else {
            return undefined
        }
    }

    async downloadHelperFile(language: 'typescript' | 'typescript-client' | 'swift-client' | 'kotlin-client') {
        try {
            this.setState({
                moreButtonLoading: true,
            })
            message.info('Helper file downloading...')
            const content = await this.generateHelperFile(language)
            if (!content) throw new Error('Content not found')
            let fileName = 'rio_auto_generated'
            switch (language) {
                case 'typescript':
                case 'typescript-client':
                    fileName += '.ts'
                    break
                case 'kotlin-client':
                    fileName += '.kt'
                    break
                case 'swift-client':
                    fileName += '.swift'
                    break
                default:
                    break
            }
            const url = window.URL.createObjectURL(new Blob([content]))
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = fileName
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
        } catch (e: any) {
            notification.error({
                placement: 'bottomRight',
                message: e.toString(),
            })
        }
        this.setState({
            moreButtonLoading: false,
        })
    }

    async getInterfaceFile() {
        const createInterfaceFileResponse = await this.ctx?.instance?.call<any>({
            method: RootProjectClassMethods.generateHelperFile,
        })
        if (createInterfaceFileResponse && createInterfaceFileResponse.data) {
            return createInterfaceFileResponse.data
        } else {
            return undefined
        }
    }

    async componentDidMount() {
        this.ctx = this.context

        this.classInstance = await this.props.rootProjectSdk.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.RetterClass,
            instanceId: `${this.projectId}_${this.classId}`,
        })
        this.projectInstance = await this.props.rootProjectSdk.getCloudObject({
            useLocal: true,
            classId: RootProjectClassEnums.Project,
            instanceId: this.projectId,
        })
        await this.init()
        this.prepareMenu()
        window.addEventListener('beforeunload', this.beforeUnloadListener)
        this.setState({
            routeHistoryUnregister: this.props.routeComponentProps.history.block((l, a) => {
                if (this.state.changeHash !== this.getChangeHash(this.state.changedFiles)) {
                    return 'Are you sure you want to close?'
                }
            }),
        })
    }

    componentWillUnmount() {
        if (this.state.publicSub) this.state.publicSub.unsubscribe()
        topMenuStore.dispatch({
            type: ActionTypes.TOP_MENU_CHANGED.types.CHANGED,
            data: {
                extraMenu: [],
            },
        })
        window.removeEventListener('beforeunload', this.beforeUnloadListener)
        if (this.state.routeHistoryUnregister) this.state.routeHistoryUnregister()
    }

    async init() {
        if (!this.classInstance) throw new Error('Class instance is not defined')
        this.setState({
            loading: true,
        })
        const defaultActiveKey = 'template.yml'
        const response = await this.classInstance.call<any>({
            method: RootProjectClassMethods.getClassFiles,
        })
        if (response.status >= 400) {
            notification.error({
                placement: 'bottomRight',
                message: response.data,
            })
            return false
        }
        const fetchedFiles: FileDetail[] = response.data
        let changedFiles: ChangedFileDetail[] = fetchedFiles.map((d, i) => {
            return {
                name: d.name,
                content: gunzipSync(Buffer.from(d.content, 'base64')).toString('utf-8'),
                status: COSFileStatus.NONE,
                _updateToken: '',
            }
        })
        changedFiles = _.sortBy(
            changedFiles.map(c => {
                return {
                    ...c,
                    sortKey: c.name.toLowerCase(),
                }
            }),
            'sortKey'
        )
        const sortPoints: any = { js: 2, yml: 0, ts: 1, json: 3 }
        changedFiles.sort((a, b) => {
            return sortPoints[a.name.split('.').pop()!] <= sortPoints[b.name.split('.').pop()!] ? -1 : 1
        })
        await this.setState({
            data: JSON.parse(JSON.stringify(changedFiles)),
            changeHash: this.getChangeHash(changedFiles),
            changedFiles,
        })
        const defaultFile = this.state.data.find(d => d.name === defaultActiveKey)
        if (defaultFile) {
            await this.setState({
                tabPanes: [defaultFile],
                activeTabPane: defaultActiveKey,
            })
        }
        const templateYamlFile = changedFiles.find(f => f.name === 'template.yml')
        if (templateYamlFile) {
            this.setState({
                nomnomlObject: getNomnoml(templateYamlFile.content),
            })
        }
        this.setState({
            loading: false,
        })
    }

    openFileInCodeEditor(fileName: string) {
        const file = this.state.changedFiles.find(d => d.name === fileName)
        if (!file) return
        const opened = this.state.tabPanes.find(p => p.name === fileName)
        if (!opened) {
            this.setState({
                tabPanes: [...this.state.tabPanes, ...[JSON.parse(JSON.stringify(file))]],
            })
        }
        this.setState({
            activeTabPane: fileName,
        })
        return
    }

    async tabOnEdit(e: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') {
        switch (action) {
            case 'remove':
                await this.closeOpenedFile(e as string)
                break
            default:
                return
        }
    }

    async closeOpenedFile(fileName: string) {
        const fileIndex = this.state.tabPanes.findIndex(p => p.name === fileName)
        if (fileIndex === -1) return
        const previousLastIndex = fileIndex - 1
        const nextFirstIndex = fileIndex + 1
        const fileData = this.state.data.find(d => d.name === fileName)
        const changedFile = this.state.changedFiles.find(f => f.name === fileName)
        if (changedFile && fileData && changedFile.content !== fileData.content && !(await this.showCloseFileConfirmation(fileName))) return
        await this.setState({
            activeTabPane:
                previousLastIndex > -1 ? this.state.tabPanes[previousLastIndex].name : nextFirstIndex < this.state.tabPanes.length ? this.state.tabPanes[nextFirstIndex].name : '',
        })
        const index = this.state.changedFiles.findIndex(f => f.name === fileName)
        if (index !== -1 && fileData) {
            this.state.changedFiles[index].content = fileData.content
            if (this.state.changedFiles[index].status === COSFileStatus.EDITED) this.state.changedFiles[index].status = COSFileStatus.NONE
        }
        if (!this.state.changedFiles.length) {
            this.setState({
                activeTabPane: '',
            })
        }
        await this.setState({
            tabPanes: this.state.tabPanes.filter(p => p.name !== fileName),
            changedFiles: this.state.changedFiles,
        })
    }

    tabsOnChange(key: string) {
        this.setState({
            activeTabPane: key,
        })
    }

    onEditorChange(fileName: string, context?: string) {
        const index = this.state.changedFiles.findIndex(p => p.name === fileName)
        const fileData = JSON.parse(JSON.stringify(this.state.data.find(d => d.name === fileName) || ''))
        if (index !== -1) {
            this.state.changedFiles[index].content = context || ''
            if (fileData && this.state.changedFiles[index].status !== COSFileStatus.DELETED) {
                this.state.changedFiles[index].status = fileData.content !== context ? COSFileStatus.EDITED : COSFileStatus.NONE
            }
        }
        this.setState({
            changedFiles: JSON.parse(JSON.stringify(this.state.changedFiles.filter(Boolean))),
        })
        if (fileName === 'template.yml') {
            this.setState({
                nomnomlObject: getNomnoml(context),
            })
        }

        if (this.editorDebouncingTimer) return

        const extension = fileName.split('.').pop()
        if (extension === 'ts' && context) {
            this.editorDebouncingTimer = true
            setTimeout(() => {
                this.editorDebouncingTimer = false
                // this.ata(context)
            }, 1000)
        }
    }

    async showCloseFileConfirmation(fileName?: string) {
        if (fileName) {
            return new Promise(resolve => {
                Modal.confirm({
                    title: 'Do you want to close?',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <>
                            This tab <b>{fileName}</b> has unsaved changes which will be lost if you choose to close it.
                        </>
                    ),
                    okText: 'Close',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    onOk() {
                        resolve(true)
                    },
                    onCancel() {
                        resolve(false)
                    },
                })
            })
        }
    }

    async showDeleteFileConfirmation(fileName?: string) {
        if (fileName) {
            return new Promise(resolve => {
                Modal.confirm({
                    title: 'Do you want to delete?',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <>
                            Are you sure you want to delete <b>{fileName}</b>?
                        </>
                    ),
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    onOk() {
                        resolve(true)
                    },
                    onCancel() {
                        resolve(false)
                    },
                })
            })
        }
    }

    async showDeployClassConfirmation() {
        return new Promise(resolve => {
            Modal.confirm({
                title: 'Do you want to deploy?',
                icon: <ExclamationCircleOutlined />,
                content: <>Are you sure you want to deploy project?</>,
                okText: 'Deploy',
                cancelText: 'Cancel',
                onOk() {
                    resolve(true)
                },
                onCancel() {
                    resolve(false)
                },
            })
        })
    }

    createFileSuccessCallback(fileName: string) {
        const newFile: ChangedFileDetail = {
            name: fileName,
            content: '',
            status: COSFileStatus.ADDED,
            _updateToken: '',
        }
        this.setState({
            changedFiles: [...this.state.changedFiles, ...[newFile]],
            tabPanes: [...this.state.tabPanes, ...[newFile]],
            activeTabPane: newFile.name,
        })
    }

    async deleteFile(fileName: string) {
        let fileIndex = this.state.changedFiles.findIndex(d => d.name === fileName)
        if (fileIndex !== -1) {
            const files = JSON.parse(JSON.stringify(this.state.changedFiles))
            if (!(await this.showDeleteFileConfirmation(fileName))) return
            if (files[fileIndex].status === COSFileStatus.ADDED) {
                delete files[fileIndex]
            } else {
                files[fileIndex].status = COSFileStatus.DELETED
            }
            await this.setState({
                changedFiles: files.filter(Boolean),
            })
            await this.tabOnEdit(fileName, 'remove')
        }
    }

    async rollbackDelete(fileName: string) {
        const fileIndex = this.state.changedFiles.findIndex(d => d.name === fileName)
        if (fileIndex !== -1) {
            const files = JSON.parse(JSON.stringify(this.state.changedFiles))
            const fileData = this.state.data.find(d => d.name === fileName)
            if (fileData && files[fileIndex].content !== fileData.content) {
                files[fileIndex].status = COSFileStatus.EDITED
            } else {
                files[fileIndex].status = COSFileStatus.NONE
            }
            await this.setState({
                changedFiles: files,
            })
        }
    }

    getFileStatusInChangedFiles(fileName: string) {
        const file = this.state.changedFiles.find(c => c.name === fileName)
        if (!file) return COSFileStatus.NONE
        return file.status
    }

    async saveChanges() {
        if (!this.classInstance) throw new Error('class instance is not defined')
        if (this.state.changeHash === this.getChangeHash(this.state.changedFiles)) return false
        this.setState({
            saveChangesLoading: true,
        })
        const templateFile = this.state.changedFiles.find(f => f.name === 'template.yml')
        if (templateFile) {
            try {
                YAML.parse(templateFile.content)
            } catch (e: any) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.toString(),
                })
                this.setState({
                    saveChangesLoading: false,
                })
                return false
            }
        }
        const preparedData: ISaveCloudObjectFileModel[] = this.state.changedFiles
            .filter(f => f.status !== COSFileStatus.NONE)
            .map(f => {
                return {
                    name: f.name,
                    classId: this.classId,
                    content: gzipSync(Buffer.from(f.content)).toString('base64'),
                    status: f.status,
                    _updateToken: f._updateToken,
                }
            })
        const response = await this.classInstance.call<any>({
            method: RootProjectClassMethods.saveClassFiles,
            body: {
                files: preparedData,
            },
        })
        if (response.status >= 400) {
            notification.error({
                placement: 'bottomRight',
                message: response.data,
            })
            return false
        }
        let changedFiles: ChangedFileDetail[] = JSON.parse(JSON.stringify(this.state.changedFiles))
        for (const resp of preparedData) {
            const index = changedFiles.findIndex(f => f.name === resp.name)
            if (index !== -1) {
                if (changedFiles[index].status === COSFileStatus.DELETED) {
                    changedFiles = changedFiles.filter(f => f.name !== changedFiles[index].name)
                } else {
                    changedFiles[index].status = COSFileStatus.NONE
                    changedFiles[index]._updateToken = resp._updateToken || ''
                }
            }
        }

        this.setState({
            data: changedFiles,
            changedFiles,
            changeHash: this.getChangeHash(changedFiles),
            saveChangesLoading: false,
        })
        //await this.init()
    }

    getFileTypeByExtension(fileName: string) {
        const extension = fileName.split('.').pop()
        switch (extension) {
            case 'ts':
                return 'typescript'
            case 'js':
                return 'javascript'
            case 'yml':
                return 'yaml'
            case 'json':
                return 'json'
            default:
                return 'none'
        }
    }

    showDiff(fileName: string) {
        const original = this.state.data.find(d => d.name === fileName)
        const modified = this.state.changedFiles.find(c => c.name === fileName)
        if (!original || !modified) return
        const diff = {
            fileName,
            language: fileName.endsWith('yaml') ? 'yaml' : 'typescript',
            original: original.content,
            modified: modified.content,
        }

        Modal.info({
            width: 1000,
            title: 'Diff - ' + diff.fileName,
            content: (
                <div>
                    <DiffEditor language={diff.language} height={'70vh'} original={diff.original} modified={diff.modified} />
                </div>
            ),
            onCancel() {},
            onOk() {},
        })
    }

    async deployClass(force: boolean = false) {
        if (!this.classInstance) throw new Error('class instance is not defined')
        if (!this.projectInstance) throw new Error('project instance is not defined')
        if (!(await this.showDeployClassConfirmation())) return
        this.setState({
            deployLoading: true,
        })
        if (this.state.publicSub) this.state.publicSub.unsubscribe()
        let deploymentId: string
        try {
            const { data } = await this.projectInstance.call<any>({
                method: RootProjectClassMethods.deployClass,
                body: {
                    force,
                },
                headers: {
                    'x-rio-console': 'true',
                },
            })
            deploymentId = data.requestId
        } catch (e: any) {
            if (e.response) {
                notification.error({
                    placement: 'bottomRight',
                    message: e.response.data.message,
                })
            }
        }
        const publicSub = this.projectInstance.state?.public?.subscribe(
            (event: {
                deployments?: Record<
                    string,
                    {
                        status: 'started' | 'ongoing' | 'failed' | 'finished'
                        statusMessage: string
                        error_stack?: string[]
                        createdAt: number
                        createdBy: string
                        id: string
                    }
                >
            }) => {
                const deployments = event.deployments || {}
                const deployment = deployments[deploymentId]

                if (deployment) {
                    const hasErrorStack = Array.isArray(deployment.error_stack) && deployment.error_stack.length > 0
                    const notify: ArgsProps = {
                        icon: <></>,
                        key: 'deployment',
                        placement: 'bottomRight',
                        message: deployment.status,
                        description: `${deployment.statusMessage}${hasErrorStack ? ' (click for details)' : ''}`,
                        duration: 0,
                        onClick: () => {
                            notification.destroy('deployment')
                            if (Array.isArray(deployment.error_stack) && deployment.error_stack.length > 0) {
                                Modal.error({
                                    title: 'Deployment Error',
                                    content: (
                                        <div>
                                            <Title level={3}>{deployment.statusMessage}</Title>
                                            <Timeline style={{ marginTop: 24 }}>
                                                {deployment.error_stack.map((e, i) => (
                                                    <Timeline.Item color="red" key={i}>
                                                        {e}
                                                    </Timeline.Item>
                                                ))}
                                            </Timeline>
                                        </div>
                                    ),
                                    width: 800,
                                })
                            }
                        },
                    }
                    switch (deployment.status) {
                        case 'failed':
                            notify['icon'] = (
                                <>
                                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                                </>
                            )
                            notification.open(notify)
                            break
                        case 'finished':
                        case 'started':
                            notify['icon'] = (
                                <>
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </>
                            )
                            notification.open(notify)
                            break
                        case 'ongoing':
                            notify['icon'] = (
                                <>
                                    <InfoCircleTwoTone />
                                </>
                            )
                            notification.open(notify)
                            break
                    }
                }
            }
        )
        this.setState({
            publicSub,
        })
        this.setState({
            deployLoading: false,
        })
        //await this.init()
    }

    async generateRioFile() {
        if (this.monaco) {
            this.setState({
                moreButtonLoading: true,
            })
            const context = await this.getInterfaceFile()
            if (!context) return false
            const rioFileName = 'rio.ts'
            const index = this.state.changedFiles.findIndex(p => p.name === rioFileName)
            const fileData = JSON.parse(JSON.stringify(this.state.data.find(d => d.name === rioFileName) || ''))
            if (index !== -1) {
                this.state.changedFiles[index].content = context || ''
                if (fileData && this.state.changedFiles[index].status !== COSFileStatus.DELETED) {
                    this.state.changedFiles[index].status = fileData.content !== context ? COSFileStatus.EDITED : COSFileStatus.NONE
                }
                this.setState({
                    changedFiles: JSON.parse(JSON.stringify(this.state.changedFiles.filter(Boolean))),
                })
            } else {
                const newFile: ChangedFileDetail = {
                    name: rioFileName,
                    content: context,
                    status: COSFileStatus.ADDED,
                    _updateToken: '',
                }
                this.setState({
                    changedFiles: [...this.state.changedFiles, ...[newFile]],
                    tabPanes: [...this.state.tabPanes, ...[newFile]],
                    activeTabPane: newFile.name,
                })
            }
            // this.addLibToMonaco(this.monaco, context, rioFileName)
            this.setState({
                moreButtonLoading: false,
            })
        }
    }

    async handleEditorDidMount(editor: Monaco, monaco: Monaco, fileName: string) {
        this.monaco = monaco
        await monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
        });
        // await monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
        //     ...monaco.languages.typescript.typescriptDefaults.getCompilerOptions(),
        //     // moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        //     target: monaco.languages.typescript.ScriptTarget.ES2020,
        //     allowSyntheticDefaultImports: true,
        //     // target: monaco.languages.typescript.ScriptTarget.ES2016,
        //     allowNonTsExtensions: true,
        //     moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
        //     module: monaco.languages.typescript.ModuleKind.CommonJS,
        //     noEmit: true,
        //     typeRoots: ['node_modules/@types'],
        // })

        // for (const datum of this.state.data) {
        //     this.addLibToMonaco(monaco, datum.content, datum.name)
        // }

        await editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyL, function () {
            editor.getAction('editor.action.formatDocument').run()
        })

        await editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, async () => {
            await this.saveChanges()
        })

        await editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyD, async () => {
            if (this.state.changeHash === this.getChangeHash(this.state.changedFiles)) {
                await this.deployClass()
            } else {
                message.warning('Save the changes before deployment')
            }
        })

        await editor.onMouseDown(this.editorOnDefinitionClick)

        const code = editor.getModel()!.getValue()
        // this.ata(code)
    }

    // addLibraryToRuntime(code: string, _path: string) {
    //     // if (!this.monaco) return

    //     // const defaults = this.monaco.languages.typescript.typescriptDefaults

    //     // const path = 'file://' + _path
    //     // defaults.addExtraLib(code, path)
    //     // const uri = this.monaco.Uri.file(path)
    //     // if (this.monaco.editor.getModel(uri) === null) {
    //     //     this.monaco.editor.createModel(code, 'javascript', uri)
    //     // }
    // }

    editorOnDefinitionClick(e: any) {
        if (e.event.metaKey && e.event.target.className.includes('goto-definition-link')) {
            const file = this.state.changedFiles.find(f => f.name.startsWith(e.event.target.innerText))
            if (file) {
                this.openFileInCodeEditor(file.name)
            }
        }
    }

    // addLibToMonaco(monaco: Monaco, context: string, path: string) {
    //     monaco.languages.typescript.typescriptDefaults.addExtraLib(context, `file:///${path}`)
    // }

    getChangeHash(changedFiles: ChangedFileDetail[]) {
        return Buffer.from(JSON.stringify(changedFiles)).toString('base64')
    }

    showEditorInfo() {
        Modal.info({
            title: 'Editor',
            content: (
                <div>
                    <p>
                        <Tag color="default">Cmd/Ctrl + S</Tag> = Save Changes
                    </p>
                    <p>
                        <Tag color="default">Cmd/Ctrl + Shift + L</Tag> = Pretty
                    </p>
                    <p>
                        <Tag color="default">Cmd/Ctrl + D</Tag> = Deploy
                    </p>
                </div>
            ),
            onOk() {},
        })
    }

    getFileIcon = (fileName: string) => {
        if (fileName.endsWith('.yml')) {
            return <RadiusSettingOutlined />
        } else if (fileName.endsWith('.ts')) {
            return <TypescriptIcon />
        } else if (fileName.endsWith('.js')) {
            return <JavascriptIcon />
        } else {
            return <FileOutlined />
        }
    }

    getColoredFileName = (fileName: string) => {
        switch (this.getFileStatusInChangedFiles(fileName)) {
            case COSFileStatus.NONE:
                return <>{fileName}</>
            case COSFileStatus.DELETED:
                return (
                    <>
                        <Text type="danger">{fileName}&nbsp;</Text>
                        <Badge status="error" />
                    </>
                )
            case COSFileStatus.ADDED:
                return (
                    <>
                        <Text type="success">{fileName}&nbsp;</Text>
                        <Badge status="success" />
                    </>
                )
            case COSFileStatus.EDITED:
                return (
                    <>
                        <Text type="warning">{fileName}&nbsp;</Text>
                        <Badge status="warning" />
                    </>
                )
            default:
                return null
        }
    }

    render() {
        return (
            <>
                <CustomSpinner spinning={this.state.loading}>
                    <Layout style={{ height: 'calc(100vh-64px)' }}>
                        <Layout.Sider style={{ overflow: 'auto', background: this.props.token.colorBgContainer, borderRadius: 12 }}>
                            <div
                                style={{
                                    height: '70vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Menu
                                    selectedKeys={[this.state.activeTabPane]}
                                    mode="inline"
                                    onClick={e => {
                                        this.openFileInCodeEditor(e.key)
                                    }}
                                >
                                    {this.state.changedFiles.map(item => {
                                        return (
                                            <Menu.Item key={item.name} icon={this.getFileIcon(item.name)}>
                                                {this.getColoredFileName(item.name)}
                                            </Menu.Item>
                                        )
                                    })}
                                </Menu>
                            </div>
                        </Layout.Sider>
                        <Layout style={{ overflow: 'auto', marginLeft: 15, height: 'calc(100vh-64px)' }}>
                            {this.state.tabPanes.length > 0 ? (
                                <>
                                    <Tabs
                                        onChange={this.tabsOnChange}
                                        activeKey={this.state.activeTabPane}
                                        hideAdd
                                        onEdit={async (e, a) => {
                                            await this.tabOnEdit(e, a)
                                        }}
                                        type="editable-card"
                                        tabBarGutter={4}
                                        tabBarStyle={{
                                            marginBottom: 0,
                                        }}
                                    >
                                        {this.state.tabPanes.map(pane => (
                                            <Tabs.TabPane
                                                tab={
                                                    <>
                                                        {pane.name.endsWith('.yml') ? <RadiusSettingOutlined /> : null}
                                                        {pane.name.endsWith('.ts') ? <TypescriptIcon /> : null}
                                                        {pane.name.endsWith('.js') ? <JavascriptIcon /> : null}
                                                        {this.getFileStatusInChangedFiles(pane.name) === COSFileStatus.NONE ? <>{pane.name}</> : null}
                                                        {this.getFileStatusInChangedFiles(pane.name) === COSFileStatus.DELETED ? (
                                                            <>
                                                                <Text type="danger">{pane.name}&nbsp;</Text>
                                                                <Badge status="error" />
                                                            </>
                                                        ) : null}
                                                        {this.getFileStatusInChangedFiles(pane.name) === COSFileStatus.ADDED ? (
                                                            <>
                                                                <Text type="success">{pane.name}&nbsp;</Text>
                                                                <Badge status="success" />
                                                            </>
                                                        ) : null}
                                                        {this.getFileStatusInChangedFiles(pane.name) === COSFileStatus.EDITED ? (
                                                            <>
                                                                <Text type="warning">{pane.name}&nbsp;</Text>
                                                                <Badge status="warning" />
                                                            </>
                                                        ) : null}
                                                    </>
                                                }
                                                key={pane.name}
                                            >
                                                <div
                                                    style={{
                                                        padding: '0 1px',
                                                        borderTopRightRadius: 12,
                                                        borderBottomLeftRadius: 12,
                                                        borderBottomRightRadius: 12,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <ThemeContext.Consumer>
                                                        {({ isDarkMode }) => (
                                                            <Editor
                                                                options={{
                                                                    cursorBlinking: 'smooth',
                                                                    minimap: {
                                                                        enabled: !(this.getFileTypeByExtension(pane.name) === 'yaml'),
                                                                    },
                                                                }}
                                                                defaultPath={`file:///${pane.name}`}
                                                                onChange={(v, e) => {
                                                                    this.onEditorChange(pane.name, v)
                                                                }}
                                                                key={pane.name}
                                                                height={'calc(100vh - 64px - 48px - 24px)'}
                                                                language={this.getFileTypeByExtension(pane.name)}
                                                                defaultValue={pane.content}
                                                                onMount={(editor: any, monaco: Monaco) => {
                                                                    this.handleEditorDidMount(editor, monaco, pane.name)
                                                                }}
                                                                theme={isDarkMode ? 'vs-dark' : 'light'}
                                                            />
                                                        )}
                                                    </ThemeContext.Consumer>
                                                </div>
                                            </Tabs.TabPane>
                                        ))}
                                    </Tabs>
                                </>
                            ) : (
                                <>
                                    <Result icon={<RBSIcon></RBSIcon>}></Result>
                                </>
                            )}
                        </Layout>
                    </Layout>
                </CustomSpinner>
            </>
        )
    }
}

CoEditorLayout.contextType = RootProjectContext

export default withAntdToken(CoEditorLayout)
